<template>
    <div >
  <v-data-table  id="table-fw" :headers="headers" :items="fws"  :loading="loading" min-height="500px">
    <template v-slot:top>
      <v-toolbar flat color="#598639" dark >
        <v-toolbar-title>{{ $t("Firmware") }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="750px" >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="black" dark class="mb-2 " v-bind="attrs" v-on="on">
              {{ $t("Add Firmware") }}
            </v-btn>
          </template>
          
          <v-card style="border-style: solid;  border-color: white;">
            <v-toolbar color="black" dark>
              <v-toolbar-title >
               <v-icon large class="mr-3 mb-2" color="white">mdi-paperclip</v-icon> <span class="text-h5 white--text">{{$t("Add Firmware")}}</span>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text >
                <v-container class="mt-3" >
                    <v-col cols="12" >
                        <v-file-input
                        accept=".sfi"
                        :placeholder="$t('Select your files')"
                        prepend-icon="mdi-paperclip"
                        outlined
                        v-model="files"
                        label="FWFile input"
                        @click:clear="clear"
                        :loading="loading2"
                        >
                        </v-file-input>
                    </v-col>
                </v-container>
            </v-card-text>
            <v-card-actions>
              <v-btn color="warning" @click="close">{{$t('Cancel')}}</v-btn>
                <v-spacer></v-spacer>
              <v-btn color="#196619" class="white--text" @click="save" :disabled="isSet"> {{$t('Upload')}} </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.UpdatedAt="{ item }">
        {{new Date(item.UpdatedAt).toLocaleString()}}
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon  color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template>
  </v-data-table>
  <v-col align-content="center">
    <p>{{ $t('Select nozzles to update fw') }}</p>
    <v-row>
        <v-col>
        <v-select 
            outlined    
            flat
            small-chips
            single-line
            v-model="nozzItems"
            :items="nozzles"
            item-text="bt_mac"
            item-value="id"
            multiple
            clearable
        ></v-select>
        </v-col>
    </v-row>
    <v-row>
    <v-col>
        <v-select 
            outlined    
            flat
            small-chips
            single-line
            v-model="fwUpdateID"
            :items="fws"
            item-text="file_name"
            item-value="ID"
            clearable
            :hint="$t('Choose firmware')"
            persistent-hint
        ></v-select>
    </v-col>
    <v-col>
        <v-btn dense color="#196619" class="white--text mt-3" @click="setUp" :disabled="nozzItems.length == 0">{{ $t('Proceed')}}</v-btn>
    
        <v-btn dense color="orange" class="white--text mt-3" @click="unset" :disabled="nozzItems.length == 0">{{ $t('Remove')}}</v-btn>
    </v-col>
    </v-row>
  </v-col>
  <v-dialog v-model="dialogDelete" max-width="500px">
    <v-card>
    <v-card-title class="text-h5"
        >{{$t('Are you sure you want to delete this item?')}}</v-card-title
    >
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="closeDelete"
        >{{$t('Cancel')}}</v-btn
        >
        <v-btn color="blue darken-1" text  :loading="loading2" @click="deleteItemConfirm"
        >{{$t('OK')}}</v-btn
        >
        <v-spacer></v-spacer>
    </v-card-actions>
    </v-card>
</v-dialog>
</div>         
</template>

<script>

export default {
    props: ['visible', 'nozzles'],
    data(){
        return {
            loading: false,
            loading2: false,
            dialogDelete: false,
            editedIndex: null,
            editedItem: null,
            files: null,
            fws:[],
            fwUpdateID: null,
            dialog: false,
            nozzItems: [],
            headers: [
                {
                    text: this.$t('ID'),
                    align: 'start',
                    sortable: true,
                    value: 'ID',
                },
                {
                    text: this.$t('Name'),
                    align: 'center',
                    sortable: true,
                    value: 'file_name',
                },
                {
                    text: this.$t('Uploaded'),
                    align: 'center',
                    sortable: true,
                    value: 'UpdatedAt',
                },
                {
                    text: this.$t('Actions'),
                    align: 'center',
                    value: 'actions',
                },
            ]

        }
    },
    methods: {
        clear() {
            this.files = null
        },
        setUp() {
            this.$emit('set', { nozzId: this.nozzItems, fwId: this.fwUpdateID})
            this.nozzItems = []
            this.fwUpdateID = null
        },
        unset() {
            this.$emit('unset', { nozzId: this.nozzItems})
            this.nozzItems = []
            this.fwUpdateID = null
        },
        async initialize() {
            try {
                this.loading = true
                const res = await this.$http.get("/file")
                this.fws= res.data
            } catch (e) {
                console.log(e)
                this.loading = false
            } finally {
                this.loading = false
            }
        },
        close() {
            this.dialog = false
            this.files = null
        },
        async save() {
            try {
                
                this.loading2 = true
               
                const formData = new FormData();
                formData.append("file", this.files);
                
                await this.$http.post('/file/fwfile', formData, {
                    headers: {
                    'Content-Type': 'multipart/form-data'
                    }
                })
                this.files = null

            } catch (e) {
                console.log(e)
                this.loading2 = false
            } finally {
                this.loading2 = false
                this.initialize()
                
            }
        },
    deleteItem (item) {
      this.editedIndex = this.fws.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = {}
        this.editedIndex = -1
      })
    },

    async deleteItemConfirm() {
        let res = {};
        try {
            res = await this.$http.delete(`/file/${this.editedItem.ID}`)
            this.fws.splice(this.editedIndex, 1)
            this.closeDelete()
            this.$emit("alertMsg", {
                type: "success",
                text: "Data saved OK",
            });
            } catch (e) {
            this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
            this.closeDelete()
        } 
    }
    },
    computed: {
        isSet() {
            return !(this.files instanceof File)
        }
    },
    watch: {
        visible: {
            handler(v) {
                if(v) {
                    this.initialize()
                }
            },
            immediate: true
        }
    }

}
</script>

<style>

</style>