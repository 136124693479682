<template>
<v-container>
  <v-data-table :headers="headers" :items="nozzles" :search="search" :loading="loading">
    <template v-slot:top>
      <v-toolbar flat color="#598639" dark >
        <v-toolbar-title>{{ $t("nozzles") }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="mdi</v-container>-magnify"
        label="Search"
        single-line
        hide-details
        clearable
      ></v-text-field>
      <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn  v-if="$auth.user().roles === '1'" color="black" dark class="mb-2 " v-bind="attrs" v-on="on">
              {{ $t("Add Nozzle") }}
            </v-btn>
          </template>
          <v-card style="border-style: solid;  border-color: white;">
            <v-toolbar color="black" dark>
              <v-toolbar-title >
               <v-icon large class="mr-3 mb-2" color="white">mdi-alpha-n-box</v-icon> <span class="text-h5 white--text">{{ formTitle }}</span>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text >
              <v-form v-model="valid" refs="form">
              <v-container class="mt-3" >
                <v-row>
                  <v-col cols="12"  v-show="$auth.user().roles === '1'" >
                    <v-select
                          dense
                          v-model.number="editedItem.pump_id"
                          :label="$t('Pump')"
                          :items="pumps"
                          item-text="name"
                          item-value="id"
                          persistent-hint
                          outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12"  v-show="$auth.user().roles === '1'">
                    <v-select
                          dense
                          v-model.number="editedItem.tank_id"
                          :label="$t('Tank')"
                          :items="tanks"
                          item-text="custom_name"
                          item-value="id"
                          persistent-hint
                          outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" v-show="$auth.user().roles === '1'">
                    <v-select
                          dense
                          v-model.number="editedItem.vit_id"
                          :label="$t('Vehicle reader')"
                          :items="readers"
                          item-text="name"
                          item-value="id"
                          persistent-hint
                          outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" v-show="$auth.user().roles === '1'" >
                    <v-select
                          dense
                          v-model.number="editedItem.disp_addr"
                          :label="$t('Dispenser')"
                          :items="dispensers"
                          item-text="name"
                          item-value="address"
                          persistent-hint
                          outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" >
                    <v-row>
                      <v-col cols="12" md="6" v-show="$auth.user().roles === '1'" >
                        <v-text-field
                          dense
                          outlined
                          v-model.number="editedItem.vit_channel"
                          :label="$t('Wireless channel')"
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-text-field
                          dense
                          v-model="editedItem.bt_mac"
                          :label="$t('Bluetooth MAC')"
                          :counter="17"
                          outlined
                          required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" v-show="$auth.user().roles === '1'" > 
                    <v-text-field
                      dense
                      outlined
                      v-model.number="editedItem.hose_num"
                      :label="$t('Hose number')"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-show="$auth.user().roles === '1'" > 
                    <v-text-field
                      dense
                      outlined
                      v-model.number="editedItem.totalizer"
                      :label="$t('Totalizer')"
                      type="number"
                    ></v-text-field>
                  </v-col>

                  <v-col  v-show="$auth.user().roles === '1'" cols="12" sm="6">
                    <v-switch
                      :label="$t('Nozzle position guard')"
                      v-model="editedItem.nozzle_position_guard"
                      color="#196619"
                      :true-value="1"
                      :false-value="0"
                      >
                    </v-switch>
                    <v-switch
                      :label="$t('Tamper function switch')"
                      v-model="editedItem.fw_setting"
                      color="#196619"
                      :true-value="100"
                      :false-value="0"
                      :hint="$t('tamper_on_off')"
                      persistent-hint
                      class="mb-2 pb-4"
                      >
                    </v-switch>
                    <v-slider
                      v-model="editedItem.fw_sensitivity"
                      :label="$t('Sensitivity')"
                      thumb-label
                      color="#196619"
                      :hint="$t('1 - most sensitive, 64 - least sensitive')"
                      :max="64"
                      :min="1"
                      persistent-hint
                      class="mb-2"
                    ></v-slider>
                  </v-col>  
                   
                  <v-col cols="12" sm="6" >
                        <v-radio-group 
                          v-model="editedItem.status"
                          :rules="[v => v !== null || $t('You have to choose one')]"
                        >
                          <template v-slot:label>
                            <div>{{$t('Status')}}</div>
                          </template>
                          <v-radio :value="2">
                            <template v-slot:label>
                              <div>
                                <v-icon    color="green"> mdi-check-bold </v-icon>
                                <span class="ml-2 mt-1">{{$t('Active')}}</span>
                              </div>
                            </template>
                          </v-radio>
                          <v-radio :value="255">
                            <template v-slot:label>
                              <div>
                                <v-icon color="blue"> mdi-timer-sand </v-icon>
                                <span class="ml-2 mt-1">{{$t('Acivate BT nozzle')}}</span>
                              </div>
                            </template>
                          </v-radio>
                          <v-radio :value="5">
                            <template v-slot:label>
                              <div>
                                <v-icon    color="orange"> mdi-exclamation-thick </v-icon>
                                <span class="ml-2 mt-1">{{$t('Disable nozzle tamper')}}</span>
                              </div>
                            </template>
                          </v-radio>
                          <v-radio :value="6">
                            <template v-slot:label>
                              <div>
                                <v-icon    color="indigo"> mdi-alarm-light </v-icon>
                                <span class="ml-2 mt-1">{{$t('Alarm tamper')}}</span>
                              </div>
                            </template>
                          </v-radio>
                        </v-radio-group>
                  </v-col>
                  
                  
                </v-row>  
                 
               
              </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn color="warning" @click="close"> {{$t('Cancel')}} </v-btn>
               <v-spacer></v-spacer>
              <v-btn color="#196619" class="white--text" @click="save" :disabled="!valid" :loading="loading2"> {{$t('Save')}} </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >{{$t('Are you sure you want to delete this item?')}}</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >{{$t('Cancel')}}</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm" :loading="loading2"
                >{{$t('OK')}}</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.vit_id="{ item }">
        <span> {{ getReaders(item.vit_id) }}</span> <span class="text-caption">(id:{{item.vit_id}})</span>
    </template>
    <template v-slot:item.disp_addr="{ item }">
        <span> {{ getDispenser(item.disp_addr) }}</span> <span class="text-caption">(id:{{item.disp_addr}})</span>
    </template>
    <template v-slot:item.pump_id="{ item }">
        <span> {{ getPump(item.pump_id) }}</span>
    </template>
    <template v-slot:item.tank_id="{ item }">
        <span> {{ getTank(item.tank_id) }}</span>
    </template>
    <template v-slot:item.nozzle_position_guard="{ item }">
        <v-icon v-if="item.nozzle_position_guard === 0" color="red"> mdi-cancel</v-icon>
        <v-icon v-if="item.nozzle_position_guard === 1" color="green"> mdi-check-bold </v-icon>
    </template>
    <template v-slot:item.status="{ item }">
      <v-badge
        :content="$t('update')"
        :value="item.fw_update"
        color="green"
        overlap
      >
        <v-icon v-if="[0,1].includes(item.status)" color="red"> mdi-cancel</v-icon>
        <v-icon v-else-if="item.status === 2" color="green"> mdi-check-bold </v-icon>
        <v-icon v-else-if="item.status === 5" color="orange"> mdi-exclamation-thick</v-icon>
        <v-icon v-else-if="item.status === 6" color="indigo"> mdi-alarm-light</v-icon>
        <v-icon v-else-if="item.status === 255" color="blue"> mdi-timer-sand</v-icon>
      </v-badge>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon  color="blue" class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon v-if="$auth.user().roles === '1'" color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template>
  </v-data-table>
  <v-row class="mt-8">
  <v-divider></v-divider>
  </v-row>
    <v-row class="mt-8 ml-3">
    <v-dialog
      v-model="dialogBt"
      persistent
      max-width="500px"
      min-height="300px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="black"
          dark
          v-bind="attrs"
          v-on="on"
          @click="startScan"
        >
          {{$t("Scan BT")}}
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{$t("Scan BT")}}</span>
        </v-card-title>
         
        <v-card-text >
          <v-container v-if="!loading3">
      
            <v-progress-circular
              indeterminate
              color="green"
              size="70"
            ></v-progress-circular>
          </v-container>
          <v-container v-else>
            <p>{{$t("Found BT devices")}}</p> 
            <v-row v-for="dev,i in unboundBt" :key="i">
              <v-col cols="4"><p class="font-weight-bold text-body1">{{dev.Address}}</p> </v-col>
              <v-col cols="2"><p>{{dev.Alias}}</p> </v-col>
              <v-col cols="2"><v-icon :color="dev.Paired ? 'blue' : 'red'" > {{dev.Paired ? 'mdi-bluetooth' : 'mdi-bluetooth-off'}}</v-icon> </v-col>
              <v-col cols="2"><v-icon :color="tdata.includes(dev.Address) ? 'green' : 'red'" > {{tdata.includes(dev.Address) ? 'mdi-database-check' : 'mdi-new-box'}}</v-icon> </v-col>
              <v-col cols="2"><v-btn v-show="$auth.user() === '1'" small dark color="#196619" @click="addMac(dev)" >{{$t("Add")}}</v-btn></v-col>  
              <v-col cols="12"> <v-divider></v-divider></v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogBt = false"
          >
            {{$t("Close")}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-dialog
        v-model="dialogFw"
        persistent
        max-width="800px"
        min-height="300px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-3"
            color="black"
            dark
            v-bind="attrs"
            v-on="on"
            v-if="$auth.user().roles === '1'"
          >
            {{$t("Firmware")}}
          </v-btn>
        </template>
        <v-card>
          <upload-file :visible="dialogFw" :nozzles="nozzles" @set="setUpdate" @unset="unsetUpdate"/>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn 
                @click="dialogFw = false"
              >
                {{$t("Close")}}
              </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>

</template>

<script>
import UploadFile from "../components/UploadFile.vue"

export default {
  components: { UploadFile },
  name: "nozzles",
  data() {
    return {
      dialog: false,
      dialogBt: false,
      dialogFw: false,
      dialogDelete: false,
      nozzles: [],
      dispensers: [],
      readers: [],
      pumps: [],
      tanks: [],
      valid: false,
      loading: false,
      loading2: false,
      tdata: [],
     
      rules: [
        v => v !== ''||  this.$t('Item is required'),
        v => v.length <= 20 || this.$t('A maximum  characters exceeded')
      ],
      editedItem: {
        id: null,
        pump_id: null,
        tank_id: null,
        vit_id: null,
        vit_channel: null,
        bt_mac: '',
        dispenser_id: null,
        hose_num: null,
        totalizer: null,
        status: null,
        disp_addr: null,
        nozzle_position_guard: null
      },
      defaultItem: {
        id: null,
        pump_id: null,
        tank_id: null,
        vit_id: null,
        vit_channel: null,
        bt_mac: '',
        dispenser_id: null,
        hose_num: null,
        totalizer: null,
        status: null,
        disp_addr: null,
        nozzle_position_guard: null
      },
      editedIndex: -1,
      search: '',
      headers: [
        {
          text: this.$t('ID'),
          align: 'start',
          sortable: true,
          value: 'id',
        },
        {
          text: this.$t('Pump ID'),
          align: 'center',
          sortable: true,
          value: 'pump_id',
        },
        {
          text: this.$t('Tank'),
          align: 'center',
          sortable: true,
          value: 'tank_id',
        },
        {
          text: this.$t('Vehicle Reader'),
          align: 'center',
          sortable: true,
          value: 'vit_id',
        },
        {
          text: this.$t('Wireless channel'),
          align: 'center',
          sortable: true,
          value: 'vit_channel',
        },
        {
          text: this.$t('Bluetooth MAC'),
          align: 'center',
          sortable: true,
          value: 'bt_mac',
        },
        {
          text: this.$t('Dispenser'),
          align: 'center',
          sortable: true,
          value: 'disp_addr',
        },
        {
          text: this.$t('Hose number'),
          align: 'center',
          sortable: true,
          value: 'hose_num',
        },
        {
          text: this.$t('Totalizer'),
          align: 'center',
          sortable: true,
          value: 'totalizer',
        },
        {
          text: this.$t('Pos. Guard'),
          align: 'center',
          sortable: true,
          value: 'nozzle_position_guard',
        },
        {
          text: this.$t('Active'),
          align: 'center',
          sortable: true,
          value: 'status',
        },
        {
          text: this.$t('Actions'),
          align: 'center',
          value: 'actions',
        },
      
      
      ]
        
    }
  },
  methods: {
      async initialize () {
       try {
          this.loading = true
          const prb = await this.$http.get("/nozzle")
          this.nozzles = prb.data
          const devs = await this.$http.get("/device/dispensers")
          this.dispensers = devs.data
          const rdrs = await this.$http.get("/device/vehicle_readers")
          this.readers = rdrs.data
          const p = await this.$http.get("/pump")
          this.pumps = p.data
          const t = await this.$http.get("/tank/brief")
          this.tanks = t.data
          this.tanks.map(t => t.id = parseInt(t.id))
        } catch (e) {
          console.log(e)
          this.loading = false
        } finally {
          this.loading = false
        }
    },
    
    editItem (item) {
      this.editedIndex = this.nozzles.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.nozzles.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      let res = null
      try {
        res = await this.$http.delete(`/nozzle/${this.editedItem.id}`)
        this.nozzles.splice(this.editedIndex, 1)
        this.closeDelete()
        this.$emit("alertMsg", {
          type: "success",
          text: "Data saved OK",
        });
      } catch (e) {
        this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
        this.closeDelete()
      }
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    validate () {
        return this.$refs.form.validate()
    },

    async save () {
      let res = null
      if (this.editedIndex > -1) {
        try {
          this.loading2 = true
          res = await this.$http.put("/nozzle", this.editedItem)
          Object.assign(this.nozzles[this.editedIndex], this.editedItem)
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.loading2 = false
        } finally{
          this.loading2 = false
        }
      } else {
        try {
          this.loading2 = true
          await this.$http.post("/nozzle", this.editedItem)
          this.nozzles.push(this.editedItem)
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.loading2 = false
        } finally{
          this.loading2 = false
        }
      }
      this.close()
      await this.initialize()
    },
    getDispenser(addr) {
         return this.dispensers.find(v => v.address === addr)?.name ?? 'N/A'
    },
    getReaders(id) {
         return this.readers.find(v => v.id === id)?.name ?? 'N/A'
    },
    getPump(id) {
      return this.pumps.find(v => v.id === id)?.name ?? 'N/A'
    },
    getTank(id) {
      return this.tanks.find(v => v.id === id)?.custom_name ?? 'N/A'
    },
    startScan () {
      this.tdata = this.nozzles.map(noz => noz.bt_mac)
      this.$socket.sendObj({action: 'scanon', key: this.$auth.token()})
    },
    deleteList () {
      this.$store.commit('DELETE_NOZZLE_MAC')
      this.$socket.sendObj({action: 'scanon', key: this.$auth.token()})
    },
    addMac(dev) {
      this.dialogBt = false
      this.dialog = true
      this.editedItem.bt_mac = dev.Address
    },
    setUpdate(d) {
      this.nozzles.map(async (v)=>{if(d.nozzId.includes(v.id)) { 
          v.fw_id = d.fwId || 0
          v.fw_update = d.fwId ? 1 : 0
          await this.$http.put(`/nozzle/${v.id}`, {'fw_id': v.fw_id, 'fw_update': v.fw_update})
        }
      })
    },
    unsetUpdate(ids) {
      ids.nozzId.map( async (v) => {
        await this.$http.put(`/nozzle/unset/${v}`)
        for (let a of this.nozzles) {
          if(a.id === v) {
            a.fw_id = 0
            a.fw_update = 0
            return
          }
        }
      })
    }
  },

  computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.$t('Add New Nozzle') : this.$t('Edit Nozzle')
      },
      unboundBt () {
        return this.$store.getters.getNozzlesMacs
      },
      loading3 () {
        return !!this.unboundBt.length
      }
      
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      }
    },

    created () {
      this.initialize()
    },
  
    

}
</script>

